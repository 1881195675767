<template>
  <div>
    <b-card hidden>
      <!-- FILTER START -->
      <b-row>
        <b-col md="3" class="d-flex align-items-center justify-content-center mb-1">
          <b-form-group :label="$t('labels.ticket_id')">
            <b-form-input v-model="filterSelect.ticketId" class="d-inline-block mr-1"
              :placeholder="$t('labels.ticket_id')" @input="inputSelect" />
          </b-form-group>
          <div style="padding-left:10px;">
            <b-button variant="primary" type="button" class="btn btn-icon rounded-circle mr-1 btn-primary btn-sm"
              v-b-tooltip.hover.top="$t('labels.search')" @click="modalSearchTicketId()" size="sm">
              <!-- :to="{ name: 'apps-sports-detail', params: { id: this.searchId } }"-->
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.status')">
            <v-select :value="$store.value" v-model="filterSelect.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" label="key"
              @input="inputSelect" :reduce="(status) => status.value" placeholder="Selecciona un Status" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr v-model="filterSelect.dateFrom" @input="inputSelect" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr v-model="filterSelect.dateTo" @input="inputSelect" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }" />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- search -->
          <b-col md="3" class="d-flex align-items-center justify-content-center mb-1">
            <b-form-input v-model="search" class="d-inline-block mr-1" placeholder="Filtrar" />
          </b-col>
          <!-- table -->
          <b-table md="9" :items="dataItems" :fields="fields" class="scroll" responsive>
            <template #cell(bet)="data">
              <b-button variant="outline-primary" :to="{ name: 'apps-sports-detail-tmp', params: { id: data.item.id } }">{{
                  data.item.bet
              }}</b-button>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount.REAL | currency({ symbol: "" }) }}
            </template>
            <template #cell(final_amount)="data">
              {{ data.item.final_amount.REAL | currency({ symbol: "" }) }}
            </template>
            <template #cell(created)="data">
              {{ data.item.created | moment }}
            </template>
          </b-table>
          <b>TOTALES</b>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">{{ $t('labels.showing') }} {{ "-" }} {{ $t('labels.to') }} {{
                    "-"
                }} {{ $t('labels.of') }} {{ "-" }} {{ $t('labels.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="serverParams.page" :total-rows="totalRows" :per-page="serverParams.limit"
                  first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                  :simple="true" :hide-goto-end-buttons="true">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col class="p-0" md="2" hidden>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="showData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from '@axios';
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [
        { label: "Origen", key: "source" },
        { label: "Ticket Id", key: "bet" },
        { label: "Usuario", key: "userName" },
        { label: "Fecha", key: "created" },
        { label: "Status", key: "status" },
        { label: "Monto Apostado", key: "amount" },
        { label: "Monto a Cobrar", key: "final_amount" },
      ],
      loadingData: false,
      dataItems: [],
      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      statusOptions: [{ key: "Pagado", value: "PAID" }, { key: "Pendiente", value: "PENDING" }, { key: "En juego", value: "INGAME" }, { key: "Ganadora", value: "WON" }, { key: "Vencidos", value: "EXPIRED" }, { key: "Anulados", value: "NULL_BET" }, { key: "Perdidos", value: "LOST" }],
      filterSelect: {
        ticketId: "",
        status: "",
        type: "",
        dateFrom: moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      dataReportHistory: [],
      searchId: "",

    };
  },
  // add filters
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  methods: {
    checkUsername(item) {
      return item.userName.toLowerCase().includes(this.search.toLowerCase())
    },
    checkTicket(item) {
      return item.bet.toLowerCase().includes(this.search.toLowerCase())
    },
    /*List - Index*/
    async showData({ id }) {
      this.dataIdUser = id
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL

        const params = {
          currency: this.$store.state.whitelabelCurrencyNabvar.currency,
          whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
        }

        const result = axios
          .post(`${VUE_APP_URL}/ticketsTmp`, params)
          .then(r => {
            this.dataItems = r.data
            console.log('LISTA DE SPORTSBOOK: ', r.data);
          })
          .catch(error => console.log('Error Encontrado:', error.response))

        return result

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async selectUserHierarchy(id) {
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency
      const whitelabel = 248
      const VUE_APP_URL = process.env.VUE_APP_URL
      const params = {
        "id": id,
        "currency": currency,
        "whitelabel": whitelabel
      }
      let result = await axios
        .post(`${VUE_APP_URL}/treeUsers`, params)
        .then(r => r.data == "" ? 0 : r.data)
        .catch(error => console.log(error.response))
      return result

    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    inputSelect() {
      let id = this.dataIdUser

      this.showData({ id });
    },
  },
  mounted() {

  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

